<template>
  <v-dialog v-model="visibility" width="480px">
    <div
      style="
        color: #4b5262;
        text-align: center;
        font-family: 'MacPaw Fixel';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        background: #fff;
      "
    >
      <div style="background: #fff; padding: 32px" v-if="!loggedUserPTrans">
        <span
          class="reviewModalHeader"
          style="border-bottom: 0px; padding: 0px"
          >{{ "create_review_login_title" | localize }}</span
        >
        <p style="font-size: 16px; margin-top: 8px">
          {{ "create_review_login_subtitle" | localize }}
        </p>
        <social-buttons style="margin: 20px 0px" :full_btn="true" />
        <submit-button @click="$router.push('/login')">{{
          "another_way" | localize
        }}</submit-button>
        <p style="margin-top: 20px; margin-bottom: 0px">
          {{ "create_review_confirm_rules_label" | localize }}
          <router-link to="/" style="color: #144fa9">{{
            "public_offer_contract_label" | localize
          }}</router-link
          >,<router-link to="/" style="color: #144fa9"
            >{{ "contract_carriage_passanger_and_baggage_label" | localize }}
          </router-link>
        </p>
      </div>
      <v-col class="px-0 py-0" v-else>
        <div class="reviewModalHeader">
          {{ "create_review_title" | localize }}
        </div>
        <div class="reviewModalContent">
          <v-rating
            :length="5"
            v-model="review.rating"
            color="#FED500"
            size="56px"
            background-color="#D3D3D3"
          />
          <span style="margin-top: 12px">{{
            "review_rating_label" | localize
          }}</span>
          <p
            style="margin-top: 20px; margin-bottom: 0px; color: #df3a3a"
            class="shake-animation"
          >
            {{ ratingError?.[0] | localize }}
          </p>
          <p class="reviewModalSubtitle">
            {{ "your_review_label" | localize }}
          </p>
          <v-text-field
            :label="'route_label' | localize"
            :placeholder="'choose_route' | localize"
            dense
            min-height="100px"
            color="#144FA9"
            outlined
            height="48px"
            style="border-radius: 10px"
            class="reviewTextField"
          />
          <v-textarea
            :label="'review_label' | localize"
            :placeholder="'type_review_label' | localize"
            dense
            min-height="100px"
            color="#144FA9"
            outlined
            style="border-radius: 10px"
            class="reviewTextField"
            v-model="review.review"
            :error-messages="reviewError | localize"
          />
          <submit-button @click="sendReview">{{
            "add_review_btn" | localize
          }}</submit-button>
          <submit-outline-button
            style="margin-top: 12px"
            @close="$emit('close')"
            >{{ "close_btn" | localize }}</submit-outline-button
          >
          <p style="margin-top: 36px; margin-bottom: 12px">
            {{ "create_review_rules_label" | localize }}
            <router-link to="rules" style="color: #144fa9">{{
              "our_rules_label" | localize
            }}</router-link
            >.
          </p>
        </div>
      </v-col>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import modalMixin from "../../../../mixins/modalMixin";
import SocialButtons from "../../../UI/Buttons/socialButtons.vue";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import SubmitOutlineButton from "../../../UI/Buttons/submitOutlineButton.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import reviewsService from "../../../../requests/Admin/reviewsService";
export default {
  components: { submitButton, SubmitOutlineButton, SocialButtons },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    review: {
      rating: 0,
      route: "",
      review: "",
    },
  }),
  validations: {
    review: {
      rating: {
        required,
      },
      review: {
        required,
      },
    },
  },
  methods: {
    async sendReview() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let form = new FormData();
        form.append("message", this.review.review);
        form.append("rate", this.review.rating);
        form.append("lang", this.locale.toLowerCase());
        form.append("user_id", this.loggedUserPTrans.user_id);
        await reviewsService.createReview(form).then((res) => {
          if (res.status == "Success") {
            this.$emit("close");
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
    ...mapGetters(["locale"]),
    ratingError() {
      let field = this.$v.review.rating;
      const errors = [];
      if (!field.$dirty) {
        return errors;
      } else if (field.required && this.review.rating == 0) {
        errors.push("require_rating_label");
      }
      return errors;
    },
    reviewError() {
      let field = this.$v.review.review;
      const errors = [];
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("required_field_error");
      }
      return errors;
    },
  },
};
</script>

<style scoped>
.reviewModalHeader {
  padding: 32px 32px 20px 32px;
  border-bottom: 1px solid #e4e4e4;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.reviewModalContent {
  padding: 20px 32px;
}
.reviewModalSubtitle {
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 12px 0px;
}
.shake-animation {
  animation: shake 0.5s ease-in-out 1s;
  animation-delay: 1s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 14px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
.reviewTextField.v-text-field input {
  font-size: 14px !important;
}
</style>