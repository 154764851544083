<template>
  <div
    :style="
      $vuetify.breakpoint.smAndDown ? 'padding: 40px 0px' : 'padding: 70px 0px'
    "
  >
    <v-main>
      <v-container>
        <v-row no-gutters align="center">
          <div
            style="
              width: 12px;
              height: 12px;
              background: #144fa9;
              border-radius: 50%;
            "
          />
          <v-col class="px-0 py-0">
            <h2
              style="
                color: var(--Black, #1b1b1b);
                font-family: 'MacPaw Fixel';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-left: 8px;
              "
            >
              {{ "reviews_section" | localize }}
            </h2>
          </v-col>

          <v-row
            no-gutters
            align="center"
            justify="end"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <div
              class="iconBackground swiper-button-prev-choose-us"
              slot="button-prev"
              style="margin-right: 24px"
            >
              <div class="swipeBtn" style="transform: rotate(180deg)" />
            </div>
            <div
              class="iconBackground swiper-button-next-choose-us"
              slot="button-next"
            >
              <div class="swipeBtn" />
            </div>
          </v-row>
        </v-row>
      </v-container>
    </v-main>
    <swiper
      ref="mySwiper"
      :options="swiperOptions"
      style="margin-top: 40px; margin-left: 10svw"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <swiper-slide
        v-for="review in reviewsList"
        :key="review.id"
        :cssMode="true"
        :navigation="true"
        :pagination="true"
        :mousewheel="true"
        :keyboard="true"
        :modules="swiperOptions.modules"
      >
        <reviews-card :review="review" /> </swiper-slide
    ></swiper>
    <v-main v-else>
      <v-container>
        <swiper
          ref="mySwiper"
          :options="swiperMobileOptions"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 20px;'
              : 'margin-top: 40px;margin-left: 10svw'
          "
        >
          <swiper-slide
            v-for="review in reviewsList"
            :key="review.id"
            :cssMode="true"
            :navigation="true"
            :pagination="true"
            :mousewheel="true"
            :keyboard="true"
            :modules="swiperOptions.modules"
          >
            <reviews-card :review="review" /> </swiper-slide
        ></swiper>
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="margin-top: 20px"
        >
          <div
            class="iconBackground swiper-button-prev-choose-us"
            slot="button-prev"
            style="margin-right: 24px"
          >
            <div class="swipeBtn" style="transform: rotate(180deg)" />
          </div>
          <div
            class="iconBackground swiper-button-next-choose-us"
            slot="button-next"
          >
            <div class="swipeBtn" />
          </div>
        </v-row>
      </v-container>
    </v-main>

    <v-main>
      <v-container>
        <v-row
          no-gutters
          :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
        >
          <v-btn
            class="writeReviewBtn"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'width: 100%!important;margin-top: 20px;'
                : ''
            "
            @click="showCreateReviewModal = true"
          >
            {{ "add_review_btn" | localize }}
          </v-btn>
        </v-row>
      </v-container>
    </v-main>
    <create-review-modal
      v-if="showCreateReviewModal"
      :visible="showCreateReviewModal"
      @close="showCreateReviewModal = false"
    />
  </div>
</template>
  
  <script>
import { Pagination, Navigation } from "vue-awesome-swiper";
import ReviewsCard from "../../../UI/Cards/reviewsCard.vue";
import CreateReviewModal from "./createReviewModal.vue";
import reviewsService from "../../../../requests/Admin/reviewsService";
export default {
  components: { ReviewsCard, CreateReviewModal },
  data: () => ({
    swiperOptions: {
      slidesPerView: 2.5,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next-choose-us",
        prevEl: ".swiper-button-prev-choose-us ",
      },
      modules: [Navigation, Pagination],
    },
    swiperMobileOptions: {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next-choose-us",
        prevEl: ".swiper-button-prev-choose-us ",
      },
      modules: [Navigation, Pagination],
    },
    reviewsList: [],
    showCreateReviewModal: false,
  }),
  mounted() {
    this.getReviews();
  },
  methods: {
    async getReviews() {
      await reviewsService.getReviews().then((res) => {
        if (res.status == "Success") {
          this.reviewsList = res.data;
        }
      });
    },
  },
};
</script>
  
  <style scoped>
.iconBackground {
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  background: #e7edf6;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.iconBackground:hover {
  background: #00318b;
}
.iconBackground:hover .swipeBtn {
  background-color: #fafafa;
}
.swipeBtn {
  width: 10px;
  height: 18px;
  background-color: #1b1b1bcc;
  -webkit-mask: url("../../../../assets/img/iconsSvg/swiperRightIcon.svg")
    no-repeat center;
  mask: url("../../../../assets/img/iconsSvg/swiperRightIcon.svg") no-repeat
    center;
}
.writeReviewBtn {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  border-radius: 10px;
  background: #fed500 !important;
  width: 280px !important;
  height: 48px !important;
  margin-top: 40px;
}
</style>