<template>
  <div class="reviewCard">
    <v-row no-gutters align="center">
      <v-col cols="4" xl="3" lg="3" md="3" sm="4">
        <img
          src="@/assets/img/avatar.png"
          width="100px"
          height="100px"
          alt="avatar"
          style="border-radius: 50%"
        />
      </v-col>
      <v-col>
        <p class="reviewName" style="margin: 0px 0px 10px 0px">
          {{ review?.user?.first_name }} {{ review?.user?.last_name }}
        </p>
        <v-rating color="#FED500" :value="review?.rate" readonly />
      </v-col>
    </v-row>
    <div class="reviewText" style="margin: 20px 0px 0px 0px">
     {{ review?.message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      require: true,
    },
  },
};
</script>

<style>
@media only screen and (min-width: 1024px) {
  .reviewCard {
    border-radius: 10px;
    background: #f6f8fc;
    padding: 16px;
    width: max-content;
    max-width: 480px;
    height: 280px;
    min-width: calc(100% - 20px);
  }
}
@media only screen and (max-width: 1023px) {
  .reviewCard {
    border-radius: 10px;
    background: #f6f8fc;
    padding: 16px;
    height: 280px;
  }
}
.reviewText {
  overflow-y: scroll !important;
  color: #1b1b1b;
  text-overflow: ellipsis;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 150px;
  padding-bottom: 100px;
}
.reviewName {
  color: #172b69;
  font-family: "MacPaw Fixel";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>