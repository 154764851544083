import requestService from "../requestService";
import store from '@/store';

export default {
	async createReview(form) {
		const response = await requestService.post(`/v1/feedback`, form)
		return response?.data
	},
	async updateReview(uuid, form) {
		const response = await requestService.post(`/cp_dash/feedback/${uuid}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getReviews() {
		const response = await requestService.get(`/v1/feedback`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getReviewsForAdmin(page) {
		const response = await requestService.get(`/cp_dash/feedback?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getNewReviews(page) {
		const response = await requestService.get(`/cp_dash/feedback/is_new/Yes?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getPublishedReviews(page) {
		const response = await requestService.get(`/cp_dash/feedback/is_publish/Yes?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getArchicedReviews(page) {
		const response = await requestService.get(`/cp_dash/feedback/deleted?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getStatistic() {
		const response = await requestService.get(`/cp_dash/statistic/feedback`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getReview(id) {
		const response = await requestService.get(`/feedback/${id}`,)
		return response?.data
	},
	async addReviewToMain(id) {
		const response = await requestService.post(`/cp_dash/feedback/publish/${id}`, {},
			{
				headers: {
					Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`
				}
			})
		return response?.data
	},
	async deleteReview(id) {
		const response = await requestService.delete(`/cp_dash/feedback/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async restoreReview(id) {
		const response = await requestService.post(`/cp_dash/feedback/restore/${id}`, {}, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
}